import { iUser } from "modules/auth/useAuth";
import { realtimeDatabase } from "shared/service/firebase/firebase";
import { iContextProvider } from "shared/ui/interfaces/iContextProvider";

import { User } from "firebase/auth";
import {
  child,
  onValue,
  push,
  ref,
  serverTimestamp,
  set,
} from "firebase/database";
import { iRoomPlaylist } from "modules/playlist/usePlaylist";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

interface iRoomContext {
  data: iRoomData;
  setData: Dispatch<SetStateAction<iRoomData>>;
}

interface iRoomContextProvider extends iContextProvider {
  roomKey: string;
}

export interface iRoomData {
  currentVideo: number;
  viewers: number;
}

const defaultRoomData: iRoomData = {
  currentVideo: 0,
  viewers: 1,
};

const defaultRoomContextValue: iRoomContext = {
  data: defaultRoomData,
  setData: () => {},
};

const useRoom = (roomKey: string) => {
  const [data, setData] = useState(defaultRoomData);

  useEffect(() => {
    const roomRef = ref(realtimeDatabase, `room/${roomKey}/data`);
    const realTimeListener = onValue(roomRef, (snapshot) => {
      const response = snapshot.val();
      if (!response) return;
      setData(response);
      return () => {
        if (realTimeListener) return realTimeListener;
      };
    });
  }, [roomKey]);

  return { data, setData };
};

export default useRoom;

const RoomContext = createContext<iRoomContext>(defaultRoomContextValue);

export const useRoomContext = () => useContext(RoomContext);

export const RoomContextProvider: React.FC<iRoomContextProvider> = ({
  children,
  roomKey,
}: iRoomContextProvider) => {
  return (
    <RoomContext.Provider value={useRoom(roomKey)}>
      {children}
    </RoomContext.Provider>
  );
};

export const createPartyRoom = async (video: iRoomPlaylist, user: User) => {
  const realtimeDatabaseRef = ref(realtimeDatabase);
  const partyRoomKey = push(child(realtimeDatabaseRef, "room")).key;

  set(child(realtimeDatabaseRef, "room/" + partyRoomKey), {
    playlist: [video],
  });

  return partyRoomKey;
};

export const addPartyRoomUser = async (
  roomKey: string,
  credential: User,
  user: iUser
) => {
  set(
    child(
      ref(realtimeDatabase),
      "room/" + roomKey + "/users/" + credential.uid
    ),
    {
      user: user,
      lastUpdate: serverTimestamp(),
    }
  );
};

export const deletePartyRoomUser = async (
  roomKey: string,
  credential: User
) => {
  set(
    child(
      ref(realtimeDatabase),
      "room/" + roomKey + "/users/" + credential.uid
    ),
    null
  );
};
