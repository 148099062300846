import { Grid } from "@mui/material";
import useAuthContext from "modules/auth/useAuth";
import {
  iRoomPlaylist,
  usePlaylistContext,
} from "modules/playlist/usePlaylist";
import { createPartyRoom } from "modules/room/useRoom";
import { iItem } from "modules/search/useSearch";
import React from "react";
import { useNavigate } from "react-router";
import VideoCard from "../VideoCard/VideoCard";

interface Params {
  loading: boolean;
  searchResult: any[];
  addToPlaylist?: boolean;
}

const ContentList: React.FC<Params> = ({
  loading,
  searchResult,
  addToPlaylist,
}: Params) => {
  const navigate = useNavigate();
  const { addNewVideoToPlaylist } = usePlaylistContext();
  const { credential } = useAuthContext();

  const videoSelected = async (video: iItem) => {
    const newItem = video as unknown as iRoomPlaylist;
    const newValue = `https://www.youtube.com/watch?v=${video.id.videoId}`;
    newItem.videoUrl = newValue;
    if (addToPlaylist) {
      addNewVideoToPlaylist(newItem);
    } else {
      if (!credential) {
        navigate("/auth");
        return;
      }
      const newRoomKey = await createPartyRoom(newItem, credential);
      navigate("/room/" + newRoomKey);
    }
  };

  return (
    <Grid container spacing={2} my={2} width="100%" justifyContent="center">
      {!loading &&
        searchResult.map((item: iItem) => {
          return (
            <Grid item key={item.snippet.title}>
              <VideoCard
                onClick={async () => {
                  await videoSelected(item);
                }}
                key={item.id.videoId}
                item={item}
              />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default ContentList;
