import * as Yup from "yup";

export interface iSignUpSchema {
  email: string;
  password: string;
  passwordConfirmation: string;
}

export const defaultSignUpSchema: iSignUpSchema = {
  email: "",
  password: "",
  passwordConfirmation: "",
};

export const signUpSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),

  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least 8 characters with at least one uppercase letter, one lowercase letter, one digit, and one special character"
    )
    .required("Password is required"),

  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), undefined], "Passwords must match")
    .required("Password confirmation is required"),
});
