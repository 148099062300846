import {
  CollectionReference,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
} from "firebase/firestore";
import { useAuthContext } from "modules/auth/useAuth";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useRef,
  useState,
} from "react";
import { firestoreDatabase } from "shared/service/firebase/firebase";
import { iContextProvider } from "shared/ui/interfaces/iContextProvider";
import Peer from "simple-peer";

interface Params {
  roomKey: string;
}

const useCall = ({ roomKey }: Params) => {
  const { credential } = useAuthContext();
  const uid: string = credential?.uid || "no_credential";
  const [startCall, showVideoFrame] = useState(false);
  const [stream, setStream] = useState<MediaStream>();
  const myLocalVideo = useRef<HTMLVideoElement>(null);
  const remoteVideo = useRef<HTMLVideoElement>(null);
  const connectionRef = useRef<Peer.Instance | null>(null);

  const collectionRefOffers = collection(
    firestoreDatabase,
    "calls/" + roomKey + "/offers/"
  );

  const collectionRefAnswers = collection(
    firestoreDatabase,
    "calls/" + roomKey + "/answers/"
  );

  const docRefAnswers = doc(
    firestoreDatabase,
    "calls/" + roomKey + "/answers/" + uid
  );

  const docRefOffers = doc(
    firestoreDatabase,
    "calls/" + roomKey + "/offers/" + uid
  );

  const deleteCollection = async (collectionRef: CollectionReference) => {
    try {
      const collectionQuery = query(collectionRef);

      const collectionSnapshot = await getDocs(collectionQuery);

      collectionSnapshot.forEach(async (document) => {
        await deleteDoc(document.ref);
      });
    } catch (error) {
      console.log("Error deleting collection:", error);
    }
  };

  const disconnect = async () => {
    try {
      await deleteCollection(collectionRefAnswers);
      await deleteCollection(collectionRefOffers);
      if (connectionRef.current) connectionRef.current.destroy();
      showVideoFrame(false);
    } catch (error) {
      console.log("Error leaving call:", error);
    }
  };

  const connect = async () => {
    // Setup Local Video Stream
    const currentStream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });

    setStream(currentStream);

    if (myLocalVideo.current) myLocalVideo.current.srcObject = currentStream;

    // Set a listner to quit the call when some one quits
    onSnapshot(collectionRefAnswers, (querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "removed") {
          // New document added
          if (connectionRef.current) connectionRef.current.destroy();
          showVideoFrame(false);
        }
      });
    });

    // Setup Remote call contract

    const documentSnapshot = await getDocs(collectionRefOffers);
    const alreadyHasCallOffer = documentSnapshot.size > 0;

    if (alreadyHasCallOffer) {
      const callData = documentSnapshot.docs[0].data().data;
      console.log("answerCall");
      // Setup Peer to answer a call
      const passivePeer = new Peer({
        initiator: false,
        trickle: false,
        stream,
      });

      // When the Initiator receives the signal event set a document on firebase with the data provided by signal event
      passivePeer.on("signal", async (data: any) => {
        console.log("signal");
        await setDoc(docRefAnswers, {
          data,
        });
      });

      // When PassivePeer receives a stream media from another peer set this stream as the source of remote video
      passivePeer.on("stream", (currentStream: any) => {
        console.log("stream");
        if (remoteVideo.current) remoteVideo.current.srcObject = currentStream;
      });

      // ? how do i know the call ???
      console.log(callData);
      passivePeer.signal(callData as Peer.SignalData);

      // No idea
      connectionRef.current = passivePeer;
      return;
    }

    console.log("createCall");
    // Setup initiator Peer Config
    const initiatorPeer = new Peer({ initiator: true, trickle: false, stream });

    // When the Initiator receives the signal event set a document on firebase with the data provided by signal event
    initiatorPeer.on("signal", async (data: any) => {
      console.log("signal");
      await setDoc(docRefOffers, {
        data,
      });
    });

    // When initiatorPeer receives a stream media from another peer set this stream as the source of remote video
    initiatorPeer.on("stream", (currentStream: any) => {
      console.log("stream");
      if (remoteVideo.current) remoteVideo.current.srcObject = currentStream;
    });

    // Create a Listener for this Firebase document  When an answers is set emit a signal back
    onSnapshot(collectionRefAnswers, (querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          // New document added
          const docData = change.doc.data();
          console.log("New document:", docData.data);
          initiatorPeer.signal(docData.data as Peer.SignalData);
        }
      });
    });

    // No idea
    connectionRef.current = initiatorPeer;
    return;
  };

  return {
    startCall,
    showVideoFrame,
    myLocalVideo,
    remoteVideo,
    connectionRef,
    stream,
    setStream,
    connect,
    disconnect,
  };
};

export default useCall;

// ========================================//
// ----------------CONTEXT-----------------//
// ========================================//

interface iCallContext {
  startCall: boolean;
  showVideoFrame: Dispatch<SetStateAction<boolean>>;
  myLocalVideo: React.RefObject<HTMLVideoElement>;
  remoteVideo: React.RefObject<HTMLVideoElement>;
  connectionRef: React.MutableRefObject<Peer.Instance | null>;
  stream: MediaStream | undefined;
  setStream: Dispatch<SetStateAction<MediaStream | undefined>>;
  connect: () => Promise<void>;
  disconnect: () => Promise<void>;
}

interface iCallContextProvider extends iContextProvider {
  roomKey: string;
}

const defaultCallContext: iCallContext = {
  startCall: false,
  showVideoFrame: () => {},
  myLocalVideo: { current: null } as React.RefObject<HTMLVideoElement>,
  remoteVideo: { current: null } as React.RefObject<HTMLVideoElement>,
  connectionRef: {
    current: null,
  } as React.MutableRefObject<Peer.Instance | null>,
  stream: undefined,
  setStream: () => {},
  connect: async () => {},
  disconnect: async () => {},
};

const CallContext = createContext<iCallContext>(defaultCallContext);

export const useCallContext = () => useContext(CallContext);

export const CallContextProvider: React.FC<iCallContextProvider> = ({
  children,
  roomKey,
}: iCallContextProvider) => {
  return (
    <CallContext.Provider value={useCall({ roomKey })}>
      {children}
    </CallContext.Provider>
  );
};
