import { AppBar, Box } from "@mui/material";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import useAuthContext from "modules/auth/useAuth";
import React from "react";
import { useNavigate } from "react-router";
import Brand from "shared/ui/components/Brand/Brand";
import UserOptions from "../UserOptions/UserOptions";

const Header: React.FC = () => {
  const { credential } = useAuthContext();
  const navigate = useNavigate();

  const matches = useMediaQuery("(min-width:900px)");
  return (
    <AppBar>
      <Box
        sx={{ display: "flex" }}
        py={1.5}
        px={matches ? 5 : 1.5}
        justifyContent="space-between"
      >
        <Brand />
        <Box display="flex">
          {credential ? (
            <UserOptions />
          ) : (
            <Button
              onClick={() => navigate("/auth")}
              className="mr-10"
              variant="contained"
            >
              Sign Up
            </Button>
          )}
        </Box>
      </Box>
    </AppBar>
  );
};

export default Header;
