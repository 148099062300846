import Auth from "modules/auth/ui/pages/Auth/Auth";
import RecoveryPassword from "modules/auth/ui/pages/RecoveryPassword/RecoveryPassword";
import ErrorAlert from "modules/error/components/ErrorAlert/ErrorAlert";
import Home from "modules/home/ui/pages/Home/Home";
import Room from "modules/room/ui/pages/Room/Room";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "shared/ui/components/Header/Header";
import PrivateRoute from "../components/PrivateRoute/PrivateRoute";

const ApplicationRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Header />
      <ErrorAlert />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/recovery-password" element={<RecoveryPassword />} />
        <Route
          path={"/room/:roomKey"}
          element={
            <PrivateRoute>
              <Room />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default ApplicationRouter;
