import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, Slider } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

interface Params {
  setStateFile: Dispatch<SetStateAction<Blob>>;
  onComplete?: () => void;
}

const CustomizeStep: React.FC<Params> = ({
  setStateFile,
  onComplete,
}: Params) => {
  const [completeCrop, setCompleteCrop] = useState(false);
  const [hasPreview, setHasPreview] = useState(false);
  const defaultBlob = new Blob([], { type: "text/html" });
  const [file, setFile] = useState<Blob>(defaultBlob);
  const [ImageSrc, setImageSrc] = useState("");
  const [scale, setScale] = useState(1);
  const imageRef = useRef<HTMLImageElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [crop, setCrop] = useState<Crop>({
    unit: "px", // Can be 'px' or '%'
    x: 0,
    y: 0,
    width: 150,
    height: 150,
  });

  const handleCrop = async () => {
    let canvas = canvasRef.current;

    if (!canvas) return;

    let canvasContext = canvas.getContext("2d");
    let image = imageRef.current;

    if (!canvasContext || !image) return;

    canvas.width = image.width;
    canvas.height = image.height;
    canvasContext.clearRect(0, 0, canvas.width, canvas.height);

    let xFactor = image.naturalWidth / image.offsetWidth;
    let yFactor = image.naturalHeight / image.offsetHeight;

    canvas.width = crop.width;
    canvas.height = crop.height;

    canvasContext.drawImage(
      image,
      crop.x * xFactor,
      crop.y * yFactor,
      crop.width * xFactor,
      crop.height * yFactor,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob((blob) => {
      let canvasFile = new File([blob || defaultBlob], "fileName.jpg", {
        type: "image/jpeg",
      });
      setStateFile(canvasFile);
      setCompleteCrop(true);
    }, "image/jpeg");
  };

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) setImageSrc(reader.result as string);
    };
    reader.readAsDataURL(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    if (!completeCrop) return;
    if (onComplete) onComplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completeCrop]);

  useEffect(() => {
    if (file === defaultBlob) return;
    setHasPreview(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <Box display="flex" flexDirection="column">
      {hasPreview && (
        <Box>
          <canvas
            ref={canvasRef}
            style={{ display: "none", position: "absolute" }}
          ></canvas>
          <ReactCrop
            crop={crop}
            onChange={(c) => setCrop(c)}
            locked={true}
            ruleOfThirds
          >
            <img
              ref={imageRef}
              src={ImageSrc}
              draggable={false}
              alt="user Profile Upload Preview"
              style={{
                transform: `scale(${scale})`,
                width: "100%",
                maxWidth: "350px",
              }}
            />
          </ReactCrop>
          <Slider
            min={0.001}
            max={10}
            step={0.001}
            onChange={(e, val) => setScale(val as number)}
          />
        </Box>
      )}
      <Box my={2}>
        <Button>
          <label>
            <Box display="flex" alignItems="center">
              <UploadFileIcon />
              <Typography variant="caption">
                Select your image profile
              </Typography>
            </Box>
            <input
              type="file"
              accept="image/*"
              onChange={(fileSelect: any) => {
                //Reset Crop position
                setCrop({
                  unit: "px",
                  x: 0,
                  y: 0,
                  width: 150,
                  height: 150,
                });
                if (!fileSelect.target.files[0]) return;
                let file = fileSelect.target.files[0];
                setFile(file);
              }}
              style={{ display: "none" }}
            />
          </label>
        </Button>
      </Box>
      <Box>
        <Button
          variant="contained"
          onClick={async () => {
            handleCrop();
          }}
          className="mt-5"
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

CustomizeStep.defaultProps = {
  onComplete: () => {},
};

export default CustomizeStep;
