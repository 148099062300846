import EmailIcon from "@mui/icons-material/Email";
import GoogleIcon from "@mui/icons-material/Google";
import { Box, Card } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { SyntheticEvent, useState } from "react";
import SignIn from "../SigIn/SigIn";
import SignUp from "../SignUp/SignUp";
import SocialAuth from "../SocialAuth/SocialAuth";
import TabPanel from "./TabPanel";

const AuthTabs = () => {
  const [value, setValue] = useState(0);

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Card sx={{ maxWidth: 400 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            icon={<GoogleIcon />}
            iconPosition="start"
            label="Social Auth"
            data-testid="social-auth"
          />
          <Tab
            icon={<EmailIcon />}
            iconPosition="start"
            label="Sign In"
            data-testid="sign-in"
          />
          <Tab
            icon={<EmailIcon />}
            iconPosition="start"
            label="Sign up"
            data-testid="sign-up"
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box display="flex" justifyContent="center">
          <SocialAuth />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SignIn />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SignUp />
      </TabPanel>
    </Card>
  );
};

export default AuthTabs;
