import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useMemo } from "react";
import "react-image-crop/dist/ReactCrop.css";
import AuthTabs from "../../components/AuthTabs/AuthTabs";
import { BannerContainer, DotedOverlay, Subtitle, Title } from "./styles";

const Auth: React.FC = () => {
  const bg = useMemo(() => {
    return Math.floor(Math.random() * 11) + 1;
  }, []);

  const matches = useMediaQuery("(min-width:900px)");
  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
      position="absolute"
      flexDirection={matches ? "row" : "column-reverse"}
    >
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        justifyItems="center"
        alignItems="center"
        boxSizing="border-box"
        flexDirection="column"
      >
        <AuthTabs />
      </Box>
      <BannerContainer backgroundnumber={bg}>
        <DotedOverlay>
          <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            justifyItems="center"
            alignItems="center"
            boxSizing="border-box"
          >
            <Title>Welcome back!</Title>
            <Subtitle>chose your desired authentication method</Subtitle>
          </Box>
        </DotedOverlay>
      </BannerContainer>
    </Box>
  );
};

export default Auth;
