import { useCanvasContext } from "modules/canvas/useCanvas";
import React from "react";

const Canvas: React.FC = () => {
  const { draw, startDrawing, stopDrawing, canvasRef } = useCanvasContext();

  return (
    <canvas
      onMouseDown={startDrawing}
      onMouseUp={stopDrawing}
      onMouseMove={draw}
      ref={canvasRef}
      style={{
        userSelect: "none",
        position: "absolute",
        top: 0,
        zIndex: 10,
      }}
    />
  );
};

export default Canvas;
