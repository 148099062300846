import { useAuthContext } from "modules/auth/useAuth";
import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

interface PrivateRouteProps {
  children: ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
}: PrivateRouteProps) => {
  const { credential } = useAuthContext();

  if (!credential) {
    const urlSegments = document.URL.split("/");
    if (urlSegments[3] === "room") {
      localStorage.setItem("room", urlSegments[4]);
    }

    return <Navigate to="/auth" />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
