import { Box, Button, TextField, Typography } from "@mui/material";
import { useSearchContext } from "modules/search/useSearch";
import React from "react";
import { useNavigate } from "react-router";
import TypingEffect from "shared/ui/utils/typingEffect";
const SearchInput: React.FC = () => {
  const { customSearch, setInputValue, inputValue } = useSearchContext();

  const navigate = useNavigate();
  const words = [
    "How to make a hamburger",
    "Top 10 Power Rangers fights",
    "Surfing big waves",
    "Last news",
  ];

  return (
    <Box display="flex" mt={2}>
      <TextField
        onKeyPress={(event) =>
          customSearch({ key: event.key, navigate: navigate })
        }
        value={inputValue}
        onChange={(input) => setInputValue(input.target.value)}
        placeholder={TypingEffect({ words: words })}
      />
      <Button
        onClick={() => customSearch({ key: "Enter", navigate: navigate })}
        variant="contained"
        sx={{ marginLeft: 2 }}
      >
        <Typography fontWeight="bold" color="white">
          Go
        </Typography>
      </Button>
    </Box>
  );
};

export default SearchInput;
