import { Box, Typography } from "@mui/material";
import { useVideoContext } from "modules/video/useVideo";

import React, { useMemo } from "react";

const Timer: React.FC = () => {
  const { duration, currentTime } = useVideoContext();

  const time = useMemo(() => {
    if (!duration || !currentTime) return "00:00/00:00";
    return (
      <>
        {duration * 1000 < 3600000
          ? new Date(currentTime * 1000).toISOString().substring(14, 19)
          : new Date(currentTime * 1000).toISOString().substring(11, 19)}
        /
        {duration * 1000 < 3600000
          ? new Date(duration * 1000).toISOString().substring(14, 19)
          : new Date(duration * 1000).toISOString().substring(11, 19)}
      </>
    );
  }, [currentTime, duration]);

  if (time) {
    return (
      <Box px={1} ml={2} boxSizing="border-box" display="flex">
        <Typography color="primary" variant="h6">
          {time}
        </Typography>
      </Box>
    );
  }

  return null;
};

export default Timer;
