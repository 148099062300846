import ShareIcon from "@mui/icons-material/Share";
import { Button } from "@mui/material";

const ShareButton: React.FC = () => {
  const copyCurrentUrlToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
  };

  return (
    <Button
      startIcon={<ShareIcon />}
      onClick={copyCurrentUrlToClipboard}
      variant="outlined"
    >
      Share
    </Button>
  );
};

export default ShareButton;
