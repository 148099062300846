import Bg1 from "shared/ui/assets/img/gif/atom.gif";
import Bg7 from "shared/ui/assets/img/gif/city.gif";
import Bg2 from "shared/ui/assets/img/gif/crazy.gif";
import Bg3 from "shared/ui/assets/img/gif/flow.gif";
import Bg4 from "shared/ui/assets/img/gif/hexagon.gif";
import Bg5 from "shared/ui/assets/img/gif/mirror.gif";
import Bg10 from "shared/ui/assets/img/gif/pipe.gif";
import Bg11 from "shared/ui/assets/img/gif/rain.gif";
import Bg6 from "shared/ui/assets/img/gif/rainbow.gif";
import Bg8 from "shared/ui/assets/img/gif/smoke.gif";
import Bg9 from "shared/ui/assets/img/gif/triangle.gif";
import { device } from "shared/ui/styles/breakpoints";
import styled from "styled-components";

interface Banner {
  backgroundnumber: number;
}

export const BannerContainer = styled.div<Banner>`
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
  background-size: cover;
  ${({ backgroundnumber }) => {
    const bgOptions: { [key: number]: string } = {
      1: Bg1,
      2: Bg2,
      3: Bg3,
      4: Bg4,
      5: Bg5,
      6: Bg6,
      7: Bg7,
      8: Bg8,
      9: Bg9,
      10: Bg10,
      11: Bg11,
    };
    return `background-image: url(${bgOptions[backgroundnumber]});`;
  }}

  @media ${device.mobileL} {
    height: 350px;
  }
`;

export const Content = styled.div`
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  left: 50%;
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  z-index: 1;
`;

export const Title = styled.h1`
  font-size: 46px;
  letter-spacing: 1.05px;
  color: #fff;
  margin: 0;

  @media ${device.mobileL} {
    font-size: 32px;
  }
`;

export const Subtitle = styled.h2`
  font-size: 24px;
  font-weight: 300;
  color: #fff;
  margin: 0;

  @media ${device.mobileL} {
    font-size: 16px;
  }
`;

export const DotedOverlay = styled.div`
  background-image: radial-gradient(#212121 1%, transparent 1%),
    radial-gradient(#212121 1%, transparent 1%);
  background-position: 0 0, 1px 1px;
  background-size: 5px 5px;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(50px);
  z-index: -1;
  width: 100%;
  height: 100%;
`;
