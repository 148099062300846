import CallIcon from "@mui/icons-material/Call";
import CallEndIcon from "@mui/icons-material/CallEnd";
import { Button, ButtonGroup } from "@mui/material";
import { useCallContext } from "../../useCall";

const CallControls = () => {
  const { startCall, showVideoFrame, disconnect, connect } = useCallContext();

  return (
    <ButtonGroup>
      {startCall ? (
        <Button onClick={() => showVideoFrame(false)}>
          <CallEndIcon color="secondary" onClick={disconnect} />
        </Button>
      ) : (
        <Button
          onClick={() => {
            showVideoFrame(true);
            connect();
          }}
        >
          <CallIcon />
        </Button>
      )}
    </ButtonGroup>
  );
};
export default CallControls;
