import {
  GoogleAuthProvider,
  User,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import {
  StorageError,
  UploadTaskSnapshot,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { useErrorContext } from "modules/error/useError";
import { createContext, useContext, useEffect, useState } from "react";
import { act } from "react-dom/test-utils";
import {
  auth,
  firestoreDatabase,
  storage,
} from "shared/service/firebase/firebase";
import { iContextProvider } from "shared/ui/interfaces/iContextProvider";

export type iAuthContext = {
  authenticateWithGoogle: () => Promise<void>;
  createUser: (email: string, password: string, file: Blob) => Promise<void>;
  logOut: () => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  recoveryPassword: (email: string) => Promise<void>;
  credential: User | undefined;
  uploadPercent: number | undefined;
  user: iUser | undefined;
};

export interface iUser {
  uid: string;
  email: string;
  profileImageUrl: string;
}

const defaultUser: iUser = {
  uid: "",
  email: "",
  profileImageUrl: "",
};

const defAuthContextValues: iAuthContext = {
  authenticateWithGoogle: async () => {},
  createUser: async () => {},
  logOut: async () => {},
  signIn: async () => {},
  recoveryPassword: async () => {},

  credential: {} as User,
  uploadPercent: undefined,
  user: defaultUser,
};

const useAuth = () => {
  const { throwError } = useErrorContext();
  const provider = new GoogleAuthProvider();
  const [credential, setCredential] = useState<User>();
  const [uploadPercent, setUploadPercent] = useState<number>();
  const [user, setUser] = useState<iUser | undefined>(defaultUser);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (userCredential) => {
      if (userCredential) {
        setCredential(userCredential);
      } else {
        setCredential(undefined);
        act(() => setUser(undefined));
      }
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getUserDoc = () => {
      if (credential) {
        return onSnapshot(
          doc(firestoreDatabase, "users", credential?.uid),
          (doc) => {
            const data = doc.data() as iUser;
            if (data) setUser(data);
          }
        );
      }
    };
    const unsubscribe = getUserDoc();
    return unsubscribe;
  }, [credential]);

  // --------------------------------------//
  // -----------SHARED METHODS------------ //
  //---------------------------------------//

  const getUserRemoteData = async (userUid: string) => {
    try {
      const docData = (
        await getDoc(doc(firestoreDatabase, "users/", userUid))
      ).data();

      if (docData) {
        setUser(docData as iUser);
      }
    } catch (error) {
      throwError(error);
    }
  };

  const setUserRemoteData = async (user: iUser, merge: boolean = true) => {
    try {
      await setDoc(
        doc(firestoreDatabase, "users", user.uid),
        {
          email: user.email,
          profileImageUrl: user.profileImageUrl,
        },
        { merge: merge }
      );
    } catch (error) {
      throwError(error);
    }
  };

  // --------------------------------------//
  // ----------SPECIFIC METHODS----------- //
  //---------------------------------------//

  const createUser = async (email: string, password: string, file: Blob) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      //UPLOAD USER PHOTO URL

      const { uid } = userCredential.user;
      const storageRef = ref(storage, `users/${uid}.jpg`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      const observer = (snapshot: UploadTaskSnapshot) => {
        setUploadPercent(
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        );
      };

      const handleError = (error: StorageError) => throwError(error);

      const onCompleteUpload = async () => {
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        if (email) {
          await setUserRemoteData({
            uid: uid,
            email: email,
            profileImageUrl: url,
          });
          await getUserRemoteData(uid);
        } else {
          throw new Error("No email was provided");
        }
      };

      uploadTask.on("state_changed", {
        next: (snapshot) => observer(snapshot),
        error: (error) => handleError(error),
        complete: onCompleteUpload,
      });
    } catch (error) {
      throwError(error);
    }
  };

  const authenticateWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const { uid, email, photoURL } = result.user;
      if (email && photoURL) {
        setUserRemoteData({
          uid: uid,
          email: email,
          profileImageUrl: photoURL,
        });
      } else {
        throw new Error("No email or photoUrl was provided");
      }
    } catch (error) {
      throwError(error);
    }
  };

  const signIn = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      throwError(error);
    }
  };

  const logOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      throwError(error);
    }
  };

  const recoveryPassword = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      throwError(error);
    }
  };

  return {
    credential,
    createUser,
    logOut,
    signIn,
    recoveryPassword,
    uploadPercent,
    user,
    authenticateWithGoogle,
  };
};

export default useAuth;

const AuthContext = createContext<iAuthContext>(defAuthContextValues);

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider: React.FC<iContextProvider> = ({
  children,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  value = useAuth(),
}: iContextProvider) => {
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
