import {
  Box,
  Card,
  CardContent,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import useAuthContext from "modules/auth/useAuth";
import { useErrorContext } from "modules/error/useError";
import { useNavigate } from "react-router-dom";
import RecoveryPasswordSchema from "./RecoveryPasswordSchema";

const RecoveryPassword: React.FC = () => {
  const { recoveryPassword } = useAuthContext();
  const { error, cleanError } = useErrorContext();
  const navigate = useNavigate();

  const initialValues = {
    email: "",
  };

  const handleSubmit = async (email: string) => {
    cleanError();
    await recoveryPassword(email);
    if (!error) navigate("/");
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: RecoveryPasswordSchema,
    onSubmit: (values) => handleSubmit(values.email),
  });

  return (
    <Box mt={20}>
      <Container>
        <Card sx={{ maxWidth: 400 }}>
          <CardContent>
            <Typography variant="h5" component="h1" sx={{ marginBottom: 1 }}>
              Need Some Help ?
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
              <Button variant="contained" type="submit" sx={{ marginTop: 1.5 }}>
                Recovery
              </Button>
            </form>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default RecoveryPassword;
