import React, { useMemo } from "react";
import SearchInput from "../SearchInputHome/SearchInputHome";
import {
  BannerContainer,
  Content,
  DotedOverlay,
  Subtitle,
  Title,
} from "./styles";

const Banner: React.FC = () => {
  const bg = useMemo(() => {
    return Math.floor(Math.random() * 11) + 1;
  }, []);
  return (
    <BannerContainer backgroundnumber={bg}>
      <Content>
        <Title>Let's watch it together!</Title>
        <Subtitle>Search content to create your party</Subtitle>
        <SearchInput />
      </Content>
      <DotedOverlay />
    </BannerContainer>
  );
};

export default Banner;
