import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";

import axios from "axios";
import { iContextProvider } from "shared/ui/interfaces/iContextProvider";

const defSearchContextValues = {
  inputValue: "",
  setInputValue: () => {},
  searchOptions: 0,
  setSearchOptions: () => {},
  ytSearchLoading: true,
  setYtSearchLoading: () => {},
  ytSearchResult: [],
  setYtSearchResult: () => {},
  customSearch: () => {},
};

interface iCustomSearch {
  key: string;
  navigate: any;
}

export type iSearchContext = {
  inputValue: string;
  setInputValue: Dispatch<SetStateAction<string>>;
  searchOptions: number;
  setSearchOptions: Dispatch<SetStateAction<number>>;
  ytSearchLoading: boolean;
  setYtSearchLoading: Dispatch<SetStateAction<boolean>>;
  ytSearchResult: any[];
  setYtSearchResult: Dispatch<SetStateAction<any[]>>;
  customSearch: ({ key, navigate }: iCustomSearch) => void;
};

export interface iItem {
  kind: string;
  etag: string;
  id: iId;
  snippet: iSnippet;
}

interface iId {
  kind: string;
  videoId: string;
}

export interface iSnippet {
  publishedAt: string;
  channelId: string;
  title: string;
  description: string;
  thumbnails: iThumbnails;
  channelTitle: string;
  liveBroadcastContent: string;
  publishTime: string;
}

interface iThumbnails {
  default: iThumbnail;
  medium: iThumbnail;
  high: iThumbnail;
}

interface iThumbnail {
  url: string;
  width: number;
  height: number;
}

const useSearch = () => {
  const [inputValue, setInputValue] = useState("");
  const [searchOptions, setSearchOptions] = useState(0);
  const [ytSearchLoading, setYtSearchLoading] = useState(true);
  const [ytSearchResult, setYtSearchResult] = useState<any>([{}]);

  const searchRequest = useCallback(async (search: string) => {
    setYtSearchLoading(true);
    const result = await youtubeSearch(search);
    setYtSearchResult(result);
    setYtSearchLoading(false);
  }, []);

  // TODO redo this search function
  const customSearch = ({ key, navigate }: iCustomSearch) => {
    if (key !== "Enter") return;

    searchRequest(inputValue);
    setInputValue("");
  };

  return {
    inputValue,
    setInputValue,
    searchOptions,
    setSearchOptions,
    ytSearchLoading,
    setYtSearchLoading,
    ytSearchResult,
    setYtSearchResult,
    customSearch,
  };
};

export default useSearch;

const SearchContext = createContext<iSearchContext>(defSearchContextValues);

export const useSearchContext = () => useContext(SearchContext);

export const SearchContextProvider: React.FC<iContextProvider> = ({
  children,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  value = useSearch(),
}: iContextProvider) => {
  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

const youtubeApi = axios.create({
  baseURL: "https://www.googleapis.com/youtube/v3",
  params: {
    part: "snippet",
    maxResults: 6,
    key: process.env.REACT_APP_YOUTUBE,
    type: "video,playlist",
  },
  headers: {},
});

const youtubeSearch = async (keyword: string) => {
  const response = await youtubeApi.get("/search", {
    params: {
      q: keyword,
    },
  });

  return response.data.items;
};
