import { initializeApp } from "firebase/app";
import {
  browserSessionPersistence,
  getAuth,
  setPersistence,
} from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { firebaseConfig } from "./firebaseConfig";

export const firebaseApp = initializeApp(firebaseConfig);

export const realtimeDatabase = getDatabase();
export const firestoreDatabase = getFirestore();
export const storage = getStorage();
export const auth = getAuth();

setPersistence(auth, browserSessionPersistence);
