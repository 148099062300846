import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useErrorContext } from "modules/error/useError";
import React from "react";
import "react-image-crop/dist/ReactCrop.css";
import { useNavigate } from "react-router-dom";

interface Params {
  userProfileImageUrl?: string;
  uploadPercent: number | undefined;
  email?: string;
}

const SubmittedStep: React.FC<Params> = ({
  userProfileImageUrl,
  uploadPercent,
  email,
}: Params) => {
  const navigate = useNavigate();

  const { error, cleanError } = useErrorContext();

  if (!error) {
    return (
      <Box
        justifyContent="center"
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {!userProfileImageUrl && (
          <LinearProgress
            value={uploadPercent}
            sx={{ marginBottom: 1.5, width: "100%" }}
          />
        )}

        <Avatar src={userProfileImageUrl} alt="user profile" />

        <Typography mt={2} mb={6}>
          {email}
        </Typography>
        {userProfileImageUrl && (
          <Button
            variant="contained"
            type="submit"
            sx={{ marginTop: 1.5 }}
            onClick={() => navigate("/")}
          >
            Let´s start!
          </Button>
        )}
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" mt={2} mb={6} fontWeight="bold">
        Looks like something went wrong!
      </Typography>
      <Typography mt={2} mb={6}>
        - Try to sign-in if you already have an account
      </Typography>
      <Typography mt={2} mb={6}>
        - Try to recorevy you password if you don't remember it
      </Typography>
      <ButtonGroup>
        <Button
          type="button"
          onClick={() => {
            cleanError();
            navigate("/auth");
          }}
        >
          Sign In
        </Button>
        <Button
          type="button"
          onClick={() => {
            cleanError();
            navigate("/recovery-password");
          }}
        >
          Forgot My Password
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default SubmittedStep;
