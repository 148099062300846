import { ThemeOptions, createTheme } from "@mui/material/styles";

export const themeOptions: ThemeOptions = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#03a9f4",
    },
    secondary: {
      main: "#ff5722",
    },
    background: {
      default: "#36393f",
      paper: "#2F3136",
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    h1: {
      fontFamily: "Rajdhani",
      fontWeight: 600,
    },
    h2: {
      fontFamily: "Rajdhani",
      fontWeight: 600,
    },
    h3: {
      fontFamily: "Rajdhani",
      fontWeight: 600,
    },
    h5: {
      fontFamily: "Rajdhani",
      fontWeight: 600,
    },
    h4: {
      fontFamily: "Rajdhani",
      fontWeight: 600,
    },
    h6: {
      fontFamily: "Rajdhani",
      fontWeight: 600,
    },
    button: {
      fontFamily: "Rajdhani",
      fontWeight: 600,
      fontSize: "0.9rem",
    },
  },
});
