import { Box, ButtonGroup, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import useAuthContext from "modules/auth/useAuth";
import { useErrorContext } from "modules/error/useError";
import { useNavigate } from "react-router-dom";
import PasswordField from "../PasswordField/PasswordField";
import SigInSchema from "./SigInSchema";

interface Params {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const { signIn } = useAuthContext();
  const { cleanError, error } = useErrorContext();
  const navigate = useNavigate();
  const initialValues: Params = {
    email: "",
    password: "",
  };

  const handleSubmit = async ({ email, password }: Params) => {
    try {
      cleanError();
      await signIn(email, password);
      if (error) return;
      const redirectLink = localStorage.getItem("room");
      localStorage.removeItem("room");
      if (redirectLink) {
        navigate(`/room/${redirectLink}`);
      } else {
        navigate("/");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: SigInSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <form onSubmit={formik.handleSubmit} data-testid="sign-in-form">
      <TextField
        fullWidth
        id="email"
        name="email"
        label="Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
      />
      <PasswordField
        fullWidth
        id="password"
        name="password"
        label="Password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
      />
      <Box mt={1.5}>
        <ButtonGroup>
          <Button variant="contained" type="submit">
            Sign In
          </Button>
          <Button
            type="button"
            onClick={() => {
              cleanError();
              navigate("/recovery-password");
            }}
          >
            Forgot My Password
          </Button>
        </ButtonGroup>
      </Box>
    </form>
  );
};

export default SignIn;
