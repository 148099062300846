import { Box, Button, TextField } from "@mui/material";
import { useSearchContext } from "modules/search/useSearch";
import React from "react";
import { useNavigate } from "react-router";

//TODO: make this reusable or rename
const SearchInput: React.FC = () => {
  const { customSearch, setInputValue } = useSearchContext();
  const navigate = useNavigate();

  return (
    <Box display="flex" my={2}>
      <TextField
        onKeyPress={(event) =>
          customSearch({ key: event.key, navigate: navigate })
        }
        onChange={(input) => setInputValue(input.target.value)}
        placeholder="Search new content"
        style={{ width: "100%", maxWidth: 500 }}
      />
      <Button
        variant="contained"
        type="button"
        onClick={() => customSearch({ key: "Enter", navigate: navigate })}
        sx={{ marginLeft: 1 }}
      >
        Go!
      </Button>
    </Box>
  );
};

export default SearchInput;
