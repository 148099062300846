import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import DrawIcon from "@mui/icons-material/Draw";
import PaletteIcon from "@mui/icons-material/Palette";
import { Box, Button, ButtonGroup } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { updateCanvas, useCanvasContext } from "modules/canvas/useCanvas";

import React, { useState } from "react";
import { HuePicker } from "react-color";

const DrawingController: React.FC = () => {
  const { active, setColor, color, lineWidth, roomKey, clearDrawing } =
    useCanvasContext();

  const [customize, setCustomize] = useState(false);
  const [colorInSelection, setColorInSelection] = useState(color);

  const handleCanvasActive = () => {
    if (roomKey) {
      active
        ? updateCanvas({ roomKey, active: false })
        : updateCanvas({ roomKey, active: true });
    }
  };

  const matches = useMediaQuery("(min-width:900px)");

  return (
    <Box>
      <ButtonGroup>
        <Button aria-label="draw icon" onClick={handleCanvasActive}>
          <DrawIcon />
        </Button>

        {active && (
          <>
            <Button onClick={() => setCustomize((s) => !s)}>
              {(matches || !customize) && <PaletteIcon />}
              {customize && (
                <Box
                  onBlur={() => {
                    setCustomize(false);
                  }}
                  onMouseOut={() => {
                    setColor(colorInSelection);
                  }}
                  mx={1}
                  ml={2}
                >
                  <HuePicker
                    color={colorInSelection}
                    onChange={(e) => setColorInSelection(e.hex)}
                    width="100px"
                  />
                </Box>
              )}
              <Box
                sx={{
                  marginLeft: 2,
                  width: lineWidth,
                  height: lineWidth,
                  backgroundColor: colorInSelection,
                  borderRadius: "100%",
                }}
              />
            </Button>
            {(matches || !customize) && (
              <Button
                sx={{
                  marginLeft: 2,
                }}
                onClick={clearDrawing}
              >
                <CancelPresentationIcon />
              </Button>
            )}
          </>
        )}
      </ButtonGroup>
    </Box>
  );
};

export default DrawingController;
