import { Avatar, Box } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useAuthContext from "modules/auth/useAuth";
import { useErrorContext } from "modules/error/useError";
import * as React from "react";
import { useNavigate } from "react-router-dom";

const UserOptions = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { logOut, user } = useAuthContext();
  const { cleanError, error } = useErrorContext();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    cleanError();
    logOut();
    if (error) return;
    setAnchorEl(null);
    navigate("/");
  };

  return (
    <Box>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        data-testid="button"
      >
        <Avatar src={user?.profileImageUrl} alt={user?.email} />
      </Button>
      <Menu
        id="basic-menu"
        data-testid="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>Logout</MenuItem>
      </Menu>
    </Box>
  );
};

export default UserOptions;
