import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

//TODO: TEST

import { useState } from "react";
const PasswordField = (props: any) => {
  const [toggle, setToggle] = useState(false);
  return (
    <FormControl fullWidth sx={{ marginTop: 1.5 }}>
      <InputLabel>Password</InputLabel>
      <OutlinedInput
        {...props}
        type={toggle ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setToggle((s) => !s)}
              edge="end"
            >
              {toggle ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default PasswordField;
