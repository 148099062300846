import styled from "styled-components";

interface videoOverlay {
  isCanvasActive: boolean;
}

export const VideoOverlay = styled.div<videoOverlay>`
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  ${({ isCanvasActive }) =>
    isCanvasActive ? "border: solid 1px #404eed" : ""};
`;
