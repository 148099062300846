import { ThemeProvider } from "@mui/material/styles";
import { AuthContextProvider } from "modules/auth/useAuth";
import { ErrorContextProvider } from "modules/error/useError";
import { SearchContextProvider } from "modules/search/useSearch";
import React from "react";
import ApplicationRouter from "shared/ui/routes/routes";
import GlobalStyles from "shared/ui/styles/globalstyles";
import { themeOptions } from "shared/ui/styles/theme";

const App: React.FC = () => {
  return (
    <>
      <ThemeProvider theme={themeOptions}>
        <GlobalStyles />
        <ErrorContextProvider>
          <AuthContextProvider>
            <SearchContextProvider>
              <ApplicationRouter />
            </SearchContextProvider>
          </AuthContextProvider>
        </ErrorContextProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
