import { useEffect, useState } from "react";

interface Params {
  words: string[];
}

const TypingEffect = ({ words }: Params) => {
  const wordsCount = words.length;
  const [typingInterval, setTypingInterval] = useState(550);
  const [switcher, setSwitcher] = useState(false);
  const [index, setIndex] = useState(0);
  const [currentCharacter, setCurrentCharacter] = useState(1);
  const [WordLength, setWordLength] = useState(words[0].length);
  const [direction, setDirection] = useState(1);
  const [word, setWord] = useState("");

  const typing = () => {
    // Moving character forward or backward
    if (direction) setCurrentCharacter((s) => s + 1);
    if (!direction) setCurrentCharacter((s) => s - 1);

    // Changing direction
    if (currentCharacter === WordLength - 1 && direction === 1) {
      setDirection(0);
    }
    if (currentCharacter === 0 && direction === 0) {
      setDirection(1);
    }

    // Changing Word
    if (currentCharacter === 0 && direction === 0) {
      if (index === wordsCount - 1) {
        setIndex(0);
        setWordLength(() => words[0].length);
      }
      if (index < wordsCount - 1) {
        const nextWord = words[index + 1];
        setWordLength(() => nextWord.length);
        setIndex((s) => s + 1);
      }
    }
  };

  const antMetronome = () => {
    if (currentCharacter === WordLength - 1 && direction === 1) {
      setTypingInterval(() => 2000);
    } else {
      setTypingInterval(Math.floor(Math.random() * 100) + 20);
    }
  };

  const toggle = async () => {
    setTimeout(() => {
      setSwitcher((s) => !s);
    }, typingInterval);
  };

  useEffect(() => {
    toggle();
    typing();
    antMetronome();
    setWord(words[index].substring(0, currentCharacter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switcher]);

  return `${word}`;
};

export default TypingEffect;
