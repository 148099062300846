import { onValue, ref, update } from "firebase/database";
import { iRoomData } from "modules/room/useRoom";
import { iItem, iSnippet } from "modules/search/useSearch";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { realtimeDatabase } from "shared/service/firebase/firebase";
import { iContextProvider } from "shared/ui/interfaces/iContextProvider";

const defaultPlaylist: iRoomPlaylist = {
  videoUrl: "",
  kind: "",
  etag: "",
  id: { kind: "", videoId: "" },
  snippet: {} as iSnippet,
};

interface iUpdateRoomPlaylist {
  roomKey: string;
  playlist: Array<iRoomPlaylist>;
}

const defaultPlaylistContext: iPlaylistContext = {
  playlist: [defaultPlaylist],
  setPlaylist: () => {},
  handleOnEnded: () => {},
  addNewVideoToPlaylist: async () => {},
  changeCurrentVideoTo: () => {},
};

export interface iRoomPlaylist extends iItem {
  videoUrl: string;
}

interface iPlaylistContextProvider extends iContextProvider {
  roomKey: string;
  data: iRoomData;
  setCurrentTime: Dispatch<SetStateAction<number>>;
}

export type iPlaylistContext = {
  playlist: Array<iRoomPlaylist>;
  setPlaylist: Dispatch<SetStateAction<Array<iRoomPlaylist>>>;
  handleOnEnded: () => void;
  addNewVideoToPlaylist: (newPlaylistItem: iRoomPlaylist) => Promise<void>;
  changeCurrentVideoTo: (number: number) => void;
};

const usePlaylist = (
  roomKey: string,
  data: iRoomData,
  setCurrentTime: Dispatch<SetStateAction<number>>
) => {
  const [playlist, setPlaylist] = useState([defaultPlaylist]);

  const addNewVideoToPlaylist = async (newPlaylistItem: iRoomPlaylist) => {
    const newPlaylist = [...[...playlist], newPlaylistItem];
    await updateRoomPlaylist({ roomKey, playlist: newPlaylist });
  };

  const changeCurrentVideoTo = (number: number) => {
    setCurrentTime(0);
    updateRoomCurrentVideo({
      roomKey,
      currentVideo: number,
    });
  };

  const handleOnEnded = () => {
    setCurrentTime(0);
    if (data.currentVideo + 1 >= playlist.length) return;
    updateRoomCurrentVideo({
      roomKey,
      currentVideo: data.currentVideo + 1,
    });
  };

  useEffect(() => {
    const roomRef = ref(realtimeDatabase, `room/${roomKey}/playlist`);
    const realTimeListener = onValue(roomRef, (snapshot) => {
      const response = snapshot.val();
      if (!response) return;
      setPlaylist(response);
      return () => {
        if (realTimeListener) return realTimeListener;
      };
    });
  }, [roomKey]);

  return {
    playlist,
    setPlaylist,
    handleOnEnded,
    addNewVideoToPlaylist,
    changeCurrentVideoTo,
  };
};

export default usePlaylist;

const PlaylistContext = createContext<iPlaylistContext>(defaultPlaylistContext);

export const usePlaylistContext = () => useContext(PlaylistContext);

export const PlaylistContextProvider: React.FC<iPlaylistContextProvider> = ({
  children,
  roomKey,
  data,
  setCurrentTime,
}: iPlaylistContextProvider) => {
  return (
    <PlaylistContext.Provider
      value={usePlaylist(roomKey, data, setCurrentTime)}
    >
      {children}
    </PlaylistContext.Provider>
  );
};

interface iUpdateRoomCurrentVideo {
  roomKey: string;
  currentVideo: number;
}

const updateRoomCurrentVideo = ({
  roomKey,
  currentVideo,
}: iUpdateRoomCurrentVideo) => {
  const newRoomData = {
    "data/currentVideo": currentVideo,
    "video/currentTime": 0,
  };
  update(ref(realtimeDatabase, "room/" + roomKey), newRoomData);
};

const updateRoomPlaylist = ({ roomKey, playlist }: iUpdateRoomPlaylist) => {
  update(ref(realtimeDatabase, "room/" + roomKey), {
    playlist: playlist,
  });
};
