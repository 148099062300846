import { Box } from "@mui/material";
import React from "react";
import OnlineViewers from "../OnlineViewers/OnlineViewers";
import PlaylistButtons from "../PlaylistButtons/PlaylistButtons";
import TimeController from "../TimeController/TimeController";
import Timer from "../Timer/Timer";
import VolumeControl from "../VolumeControl/VomuleControl";

const PlayerButtons: React.FC = () => {
  return (
    <Box marginTop={1}>
      <Box display="flex" marginBottom={1} alignItems="center">
        <TimeController />
        <Timer />
      </Box>
      <Box display="flex" marginBottom={1} alignItems="center">
        <VolumeControl />
        <PlaylistButtons />
        <OnlineViewers />
      </Box>
    </Box>
  );
};

export default PlayerButtons;
