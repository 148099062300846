import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import { Box, IconButton, Typography } from "@mui/material";
import { usePlaylistContext } from "modules/playlist/usePlaylist";

import { useRoomContext } from "modules/room/useRoom";
import React from "react";

const PlaylistButtons: React.FC = () => {
  const { changeCurrentVideoTo, playlist } = usePlaylistContext();
  const { data } = useRoomContext();
  const { currentVideo } = data;

  if (playlist.length > 1) {
    return (
      <Box display="flex" alignItems="center" marginLeft={2}>
        <IconButton
          onClick={() => changeCurrentVideoTo(currentVideo - 1)}
          disabled={currentVideo > 0 ? false : true}
        >
          <SkipPreviousIcon />
        </IconButton>
        <Typography
          mx={2}
          width={50}
          textAlign="center"
          variant="h6"
          color="primary"
        >
          {`${currentVideo + 1} / ${playlist.length}`}
        </Typography>
        <IconButton
          onClick={() => changeCurrentVideoTo(currentVideo + 1)}
          disabled={playlist.length === currentVideo + 1 ? true : false}
        >
          <SkipNextIcon />
        </IconButton>
      </Box>
    );
  }

  return null;
};

export default PlaylistButtons;
