import { useNavigate } from "react-router";
import BrandSvg from "shared/ui/assets/img/svg/tentax-negative-horizontal.svg";

const Brand: React.FC = () => {
  const navigate = useNavigate();
  return (
    <img
      src={BrandSvg}
      alt="tentax"
      onClick={() => navigate("/")}
      width={150}
      style={{ cursor: "pointer" }}
    />
  );
};

export default Brand;
