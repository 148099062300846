import ListIcon from "@mui/icons-material/List";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import ContentList from "modules/playlist/ui/components/ContentList/ContentList";
import { usePlaylistContext } from "modules/playlist/usePlaylist";
import { useRoomContext } from "modules/room/useRoom";
import SearchInput from "modules/search/ui/components/SearchInput/SearchInput";
import { useSearchContext } from "modules/search/useSearch";
import { useState } from "react";
import VideoCard from "../VideoCard/VideoCard";

const PlaylistController: React.FC = () => {
  const { ytSearchLoading, ytSearchResult } = useSearchContext();
  const { changeCurrentVideoTo, playlist } = usePlaylistContext();
  const { data } = useRoomContext();

  const [tab, setTab] = useState(0);

  const Search = () => {
    return (
      <Box display="flex" alignItems="center" flexDirection="column">
        <SearchInput />
        <ContentList
          loading={ytSearchLoading}
          searchResult={ytSearchResult}
          addToPlaylist={true}
        />
      </Box>
    );
  };

  const Playlist = () => {
    return (
      <Grid container spacing={2} mt={2} width="100%" justifyContent="center">
        {playlist.map((item, index) => (
          <Grid item key={index}>
            <Box
              sx={{
                border: `${
                  index === data.currentVideo ? "#2196f3 2px solid" : ""
                }`,
                filter: `${index < data.currentVideo && "grayscale(100%)"}`,
              }}
            >
              <VideoCard
                item={item}
                onClick={() => changeCurrentVideoTo(index)}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Box>
      <Tabs
        value={tab}
        onChange={(e, newValue) => setTab(newValue)}
        aria-label="icon position tabs example"
      >
        <Tab icon={<SearchIcon />} iconPosition="start" label="Search" />
        <Tab icon={<ListIcon />} iconPosition="start" label="Playlist" />
      </Tabs>

      {
        {
          0: Search(),
          1: Playlist(),
        }[tab]
      }
    </Box>
  );
};

export default PlaylistController;
