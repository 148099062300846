import { onValue, ref, update } from "firebase/database";
import useAuthContext from "modules/auth/useAuth";
import {
  Dispatch,
  MouseEvent,
  MutableRefObject,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { realtimeDatabase } from "shared/service/firebase/firebase";
import { iContextProvider } from "shared/ui/interfaces/iContextProvider";

export type iCanvasRef = HTMLCanvasElement | null;
export type iOverlayRef = HTMLDivElement | null;
export type iCanvasContextRef = CanvasRenderingContext2D | null;

interface iCanvasContext {
  active: boolean;
  setActive: Dispatch<SetStateAction<boolean>>;
  canvasRef: MutableRefObject<iCanvasRef>;
  overlayRef: MutableRefObject<iOverlayRef>;
  startDrawing: ({ nativeEvent }: MouseEvent<HTMLCanvasElement>) => void;
  stopDrawing: () => void;
  draw: ({ nativeEvent }: MouseEvent<HTMLCanvasElement>) => void;
  setColor: Dispatch<SetStateAction<string>>;
  color: string;
  setAutoClear: Dispatch<SetStateAction<boolean>>;
  drawing: boolean;
  lineWidth: number;
  setLineWidth: Dispatch<SetStateAction<number>>;
  roomKey: string | undefined;
  clearDrawing: () => void;
}

const defCanvasContextValue: iCanvasContext = {
  active: false,
  setActive: () => {},
  canvasRef: {} as MutableRefObject<iCanvasRef>,
  overlayRef: {} as MutableRefObject<iOverlayRef>,
  startDrawing: () => {},
  stopDrawing: () => {},
  draw: () => {},
  setColor: () => {},
  color: "#fff",
  setAutoClear: () => {},
  drawing: false,
  lineWidth: 3,
  setLineWidth: () => {},
  roomKey: "",
  clearDrawing: () => {},
};

const useCanvas = () => {
  const { roomKey } = useParams<any>();
  const { credential } = useAuthContext();
  const cleanTime = 100;
  const userKey = credential?.uid || "no_uid";
  const canvasRef = useRef<iCanvasRef>(null);
  const overlayRef = useRef<iOverlayRef>(null);
  const contextRef = useRef<iCanvasContextRef>(null);
  const [active, setActive] = useState(false);
  const [drawing, setDrawing] = useState(false);
  const [autoClear, setAutoClear] = useState(true);
  const [color, setColor] = useState("#fff");
  const [lineWidth, setLineWidth] = useState(5);
  const [remoteDraw, setRemoteDraw] = useState<any>();
  const [userDraw, setUserDraw] = useState<any>([]);

  // ========================================//
  // -----------OFFLINE-DRAWING--------------//
  // ========================================//

  const getRelativePosition = (x: number, y: number) => {
    const canvas = canvasRef.current;
    const overlay = overlayRef.current;
    if (!canvas || !overlay) return;
    const width = canvas.width;
    const height = canvas.height;
    const xRatio = x / width;
    const yRatio = y / height;
    return { x: xRatio, y: yRatio };
  };

  const setRelativePosition = (x: number, y: number) => {
    const canvas = canvasRef.current;
    const overlay = overlayRef.current;
    if (!canvas || !overlay) return;
    const width = canvas.width;
    const height = canvas.height;
    const xRatio = x * width;
    const yRatio = y * height;
    return { x: xRatio, y: yRatio };
  };

  const startDrawing = ({ nativeEvent }: MouseEvent<HTMLCanvasElement>) => {
    if (!active) return;
    clearDrawing();
    const { offsetX, offsetY } = nativeEvent;
    setDrawing(true);
    contextRef.current?.moveTo(offsetX, offsetY);
    setUserDraw([getRelativePosition(offsetX, offsetY)]);
  };

  const stopDrawing = () => {
    if (roomKey) {
      contextRef.current?.closePath();
      setDrawing(false);
      updateDrawings({ roomKey, newDraw: userDraw });
      clearDrawing();
    }
  };

  const clearDrawing = useCallback(() => {
    if (!autoClear) return;
    setTimeout(() => {
      const canvas = canvasRef.current;
      const context = contextRef.current;
      if (!canvas || !context) return;
      context.beginPath();
      context.clearRect(0, 0, canvas.width, canvas.height);
    }, cleanTime);
    setUserDraw([]);
  }, [autoClear]);

  const draw = ({ nativeEvent }: MouseEvent<HTMLCanvasElement>) => {
    if (!drawing) return;
    const { offsetX, offsetY } = nativeEvent;

    const newUserDraw = userDraw;
    newUserDraw.push(getRelativePosition(offsetX, offsetY));
    setUserDraw(newUserDraw);

    contextRef.current?.lineTo(offsetX, offsetY);
    contextRef.current?.stroke();
  };

  //FIRST LOAD SETUP
  useEffect(() => {
    const firstCanvasLoad = () => {
      const canvas = canvasRef.current;
      const overlay = overlayRef.current;
      if (!canvas || !overlay) return;
      const context = canvas.getContext("2d");
      if (!context) return;
      const devicePixelRatio = window.devicePixelRatio || 1;
      const width = overlay.offsetWidth * devicePixelRatio;
      const height = overlay.offsetHeight * devicePixelRatio;

      canvas.width = width;
      canvas.height = height;
      canvas.style.width = `${overlay.offsetWidth}px`;
      canvas.style.height = `${overlay.offsetHeight}px`;

      context.scale(devicePixelRatio, devicePixelRatio);
      contextRef.current = context;
    };
    firstCanvasLoad();
  }, [active]);

  //USER CHANGE PEN STYLE
  useEffect(() => {
    const handleUserChangePenStyle = () => {
      if (!contextRef.current || !canvasRef.current) return;
      contextRef.current.lineWidth = lineWidth;
      contextRef.current.strokeStyle = color;
      // updateUserPointerStyle({ roomKey, userKey, color, lineWidth });
    };
    handleUserChangePenStyle();
  }, [color, lineWidth, roomKey, userKey]);

  // FIREBASE REALTIME DATABASE SNAPSHOT

  // Listen to canvas status
  useEffect(() => {
    const roomRef = ref(realtimeDatabase, `room/${roomKey}/canvas`);
    const realTimeListener = onValue(roomRef, (snapshot) => {
      const response = snapshot.val();
      if (response) setActive(response.active);
    });

    return realTimeListener;
  }, [roomKey]);

  // Listen to new drawings
  useEffect(() => {
    const roomRef = ref(realtimeDatabase, `room/${roomKey}/draw`);
    const realTimeListener = onValue(roomRef, (snapshot) => {
      const response = snapshot.val();
      if (response) setRemoteDraw(response.drawings);
    });

    return realTimeListener;
  }, [roomKey]);

  // Listen to color change
  useEffect(() => {
    const roomRef = ref(realtimeDatabase, `room/${roomKey}/colors`);
    const realTimeListener = onValue(roomRef, (snapshot) => {
      const response = snapshot.val();
      if (response) setColor(response.color);
    });

    return realTimeListener;
  }, [roomKey]);

  // set color change
  useEffect(() => {
    if (roomKey) {
      updateColor({ roomKey, color });
    }
  }, [color, roomKey]);

  // Build remote drawings
  useEffect(() => {
    clearDrawing();
    const drawRemote = () => {
      if (remoteDraw && contextRef.current) {
        let len = remoteDraw.length;
        remoteDraw.forEach((point: any, index: any) => {
          const relativePoint = setRelativePosition(point.x, point.y);
          if (!relativePoint) return;

          //Start Drawing
          if (index === 0) {
            contextRef.current?.moveTo(relativePoint.x, relativePoint.y);
          }

          //Draw
          contextRef.current?.lineTo(relativePoint.x, relativePoint.y);
          contextRef.current?.stroke();

          //Stop Drawing
          if (index === len - 1) {
            contextRef.current?.closePath();
          }
        });
      }
    };

    setTimeout(drawRemote, cleanTime + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteDraw]);

  return {
    active,
    setActive,
    canvasRef,
    overlayRef,
    startDrawing,
    stopDrawing,
    draw,
    setColor,
    color,
    setAutoClear,
    drawing,
    lineWidth,
    setLineWidth,
    roomKey,
    clearDrawing,
  };
};

// ========================================//
// ----------------CONTEXT-----------------//
// ========================================//

const CanvasContext = createContext<iCanvasContext>(defCanvasContextValue);

export const useCanvasContext = () => useContext(CanvasContext);

export const CanvasContextProvider: React.FC<iContextProvider> = ({
  children,
}: iContextProvider) => {
  return (
    <CanvasContext.Provider value={useCanvas()}>
      {children}
    </CanvasContext.Provider>
  );
};

interface iUpdateCanvas {
  roomKey: string;
  active: boolean;
}

export const updateCanvas = ({ roomKey, active }: iUpdateCanvas) => {
  update(ref(realtimeDatabase, "room/" + roomKey), {
    "canvas/active": active,
  });
};

interface iUpdateCanvasDraw {
  roomKey: string;
  newDraw: [{}];
}

const updateDrawings = ({ roomKey, newDraw }: iUpdateCanvasDraw) => {
  const newRoomData = {
    "draw/drawings": newDraw,
  };
  update(ref(realtimeDatabase, "room/" + roomKey), newRoomData);
};

interface iUpdateCanvasColor {
  roomKey: string;
  color: string;
}

const updateColor = ({ roomKey, color }: iUpdateCanvasColor) => {
  update(ref(realtimeDatabase, "room/" + roomKey), {
    "colors/color": color,
  });
};
