import { useFormik } from "formik";
import CustomizeStep from "modules/auth/ui/components/SignUp/CustomizeStep";
import useAuthContext from "modules/auth/useAuth";
import { useErrorContext } from "modules/error/useError";
import { useEffect, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { useNavigate } from "react-router-dom";
import EmailFormStep from "./EmailFormStep";
import {
  defaultSignUpSchema,
  iSignUpSchema,
  signUpSchema,
} from "./SignUpSchema";
import SubmittedStep from "./SubmittedStep";

const SignUp: React.FC = () => {
  const [formStep, setFormStep] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [file, setFile] = useState<Blob>(new Blob([], { type: "text/html" }));

  const { createUser, uploadPercent, user } = useAuthContext();
  const { cleanError, error } = useErrorContext();

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: defaultSignUpSchema,
    validationSchema: signUpSchema,
    onSubmit: async ({ email, password }: iSignUpSchema) => {
      setEmail(email);
      setPassword(password);
      setFormStep(1);
    },
  });

  useEffect(() => {
    if (formStep === 2) {
      cleanError();
      createUser(email, password, file);
      if (error) return;
      const redirectLink = localStorage.getItem("room");
      localStorage.removeItem("room");
      if (redirectLink) {
        navigate(`/room/${redirectLink}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStep]);

  return (
    <form onSubmit={formik.handleSubmit} data-testid="sign-up-form">
      {
        {
          0: <EmailFormStep formik={formik} />,
          1: (
            <CustomizeStep
              setStateFile={setFile}
              onComplete={() => setFormStep(2)}
            />
          ),
          2: (
            <SubmittedStep
              uploadPercent={uploadPercent}
              userProfileImageUrl={user?.profileImageUrl}
              email={user?.email}
            />
          ),
        }[formStep]
      }
    </form>
  );
};

export default SignUp;
