import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import CallControls from "modules/call/ui/components/CallControlls/CallControlls";
import { CallContextProvider } from "modules/call/ui/useCall";
import DrawingController from "modules/canvas/ui/components/DrawingController/DrawingController";
import { CanvasContextProvider } from "modules/canvas/useCanvas";
import PlaylistController from "modules/playlist/ui/components/PlaylistController/PlayListController";
import { PlaylistContextProvider } from "modules/playlist/usePlaylist";
import { RoomContextProvider, useRoomContext } from "modules/room/useRoom";
import PlayerButtons from "modules/video/ui/components/PlayerButtons/PlayerButtons";
import VideoFrame from "modules/video/ui/components/VideoFrame/VideoFrame";
import { VideoContextProvider, useVideoContext } from "modules/video/useVideo";
import React, { useRef } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router";
import ShareButton from "../../components/ShareButton/ShareButton";

const PartyRoom: React.FC = () => {
  const { roomKey } = useParams<any>();
  const playerRef = useRef<ReactPlayer>(null);
  const { data } = useRoomContext();
  const { setCurrentTime } = useVideoContext();

  const matches = useMediaQuery("(min-width:900px)");

  return (
    <Box px={matches ? 10 : 0.5}>
      <RoomContextProvider roomKey={roomKey || ""}>
        <VideoContextProvider roomKey={roomKey || ""} playerRef={playerRef}>
          <PlaylistContextProvider
            roomKey={roomKey || ""}
            setCurrentTime={setCurrentTime}
            data={data}
          >
            <CanvasContextProvider>
              <CallContextProvider roomKey={roomKey || ""}>
                <Box
                  mt={10}
                  display="flex"
                  flexDirection={matches ? "row" : "column"}
                >
                  <Box width="100%">
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      mb={1}
                      pt={2}
                    >
                      <Box display="flex">
                        <DrawingController />
                        <Box ml={1}>
                          <CallControls />
                        </Box>
                      </Box>
                      <ShareButton />
                    </Box>
                    <Box>
                      <VideoFrame playerRef={playerRef} />
                    </Box>
                    <PlayerButtons />
                  </Box>
                  <Box px={matches ? 5 : 0}>
                    <PlaylistController />
                  </Box>
                </Box>
              </CallContextProvider>
            </CanvasContextProvider>
          </PlaylistContextProvider>
        </VideoContextProvider>
      </RoomContextProvider>
    </Box>
  );
};

export default PartyRoom;
