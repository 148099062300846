import AvatarGroup from "@mui/material/AvatarGroup";

const OnlineViewers = () => {
  // const { users } = useVideoContext();
  return (
    <AvatarGroup max={5}>
      {/* {users.map(() => (
        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
      ))} */}
    </AvatarGroup>
  );
};
export default OnlineViewers;
