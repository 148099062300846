import { onValue, ref, update } from "firebase/database";
import useAuthContext from "modules/auth/useAuth";
import { addPartyRoomUser, deletePartyRoomUser } from "modules/room/useRoom";
import {
  Dispatch,
  RefObject,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import ReactPlayer from "react-player";
import { realtimeDatabase } from "shared/service/firebase/firebase";
import { iContextProvider } from "shared/ui/interfaces/iContextProvider";

const defaultRoomVideo: iRoomVideo = {
  currentTime: 0,
  playing: true,
};

const defaultVideoContext: iVideoContext = {
  video: defaultRoomVideo,
  setVideo: () => {},
  currentTime: 0,
  setCurrentTime: () => {},
  duration: 0,
  setDuration: () => {},
  sliding: false,
  setSliding: () => {},
  volume: 0,
  setVolume: () => {},
  users: [],
  size: {} as iSize,
  setSize: () => {},
  setUsers: () => {},
  updateRoomPlaying: () => {},
  updateRoomTime: () => {},
  handlePause: () => {},
  handlePlay: () => {},
  handleSlideMouseUp: () => {},
  handleSlideMouseDown: () => {},
  handleSlideChange: () => {},
  handleOnReady: () => {},
  handleOnProgress: () => {},
  handleClick: () => {},
};

interface iVideoContextProvider extends iContextProvider {
  roomKey: string;
  playerRef: RefObject<ReactPlayer>;
}

interface iTimeProgress {
  playedSeconds: number;
  played: number;
  loadedSeconds: number;
  loaded: number;
}

interface iRoomVideo {
  currentTime: number;
  playing: boolean;
}

interface iUpdateRoomPlaying {
  roomKey: string;
  videoPlaying: boolean;
}

interface iUpdateRoomTime {
  roomKey: string;
  videoTime: number;
}

interface iSize {
  width: string | number;
  height: string | number;
}

export type iVideoContext = {
  video: iRoomVideo;
  setVideo: Dispatch<SetStateAction<iRoomVideo>>;
  currentTime: number;
  setCurrentTime: Dispatch<SetStateAction<number>>;
  duration: number;
  setDuration: Dispatch<SetStateAction<number>>;
  sliding: boolean;
  setSliding: Dispatch<SetStateAction<boolean>>;
  volume: number;
  setVolume: Dispatch<SetStateAction<number>>;
  users: any;
  setUsers: Dispatch<SetStateAction<any>>;
  size: iSize;
  setSize: Dispatch<SetStateAction<iSize>>;
  updateRoomPlaying: ({ roomKey, videoPlaying }: iUpdateRoomPlaying) => void;
  updateRoomTime: ({ roomKey, videoTime }: iUpdateRoomTime) => void;
  handlePlay: () => void;
  handlePause: () => void;
  handleSlideMouseUp: (mouseEvent: number) => void;
  handleSlideMouseDown: (mouseEvent: number) => void;
  handleSlideChange: (number: number) => void;
  handleOnReady: () => void;
  handleOnProgress: (timeProgress: iTimeProgress) => void;
  handleClick: (mouseEvent: number) => void;
};

const useVideo = (roomKey: string, playerRef: RefObject<ReactPlayer>) => {
  // ========================================//
  // -------------HOOK-VALUES----------------//
  // ========================================//
  const [video, setVideo] = useState({
    currentTime: 0,
    playing: true,
  });
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [sliding, setSliding] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(0.0);
  const [users, setUsers] = useState([]);
  const [size, setSize] = useState<iSize>({ width: "100%", height: "" });

  const { credential, user } = useAuthContext();

  // ========================================//
  // ------------HELPER-FUNCTION-------------//
  // ========================================//

  // Some time changes require to sync currentTime and video player current time
  const handleTimeChange = useCallback(
    (time: number) => {
      setCurrentTime(time);
      playerRef?.current?.seekTo(time);
    },
    [playerRef]
  );

  const getVideoDuration = () => {
    setDuration(playerRef?.current?.getDuration() || 0);
  };

  // ========================================//
  // ------------PASSIVE-EVENTS--------------//
  // ========================================//

  // If video is playing this function will be fired every progressInterval
  const handleOnProgress = (timeProgress: iTimeProgress) => {
    if (sliding) return;
    setCurrentTime(timeProgress.playedSeconds);
  };

  // When video component is ready
  const handleOnReady = () => getVideoDuration();

  // ========================================//
  // ------------ACTIVE-EVENTS---------------//
  // ========================================//

  const handlePlay = () => {
    updateVideoPlaying({ roomKey, videoPlaying: true });
  };

  const handlePause = () => {
    updateVideoPlaying({ roomKey, videoPlaying: false });
  };

  const handleSlideMouseDown = (targetTime: number) => {
    setSliding(true);
    handleTimeChange(targetTime);
  };

  const handleSlideMouseUp = (targetTime: number) => {
    setSliding(false);
    handleTimeChange(targetTime);
    updateVideoTime({ roomKey, videoTime: targetTime });
  };

  const handleClick = (targetTime: number) => {
    handleTimeChange(targetTime);
    updateVideoTime({ roomKey, videoTime: targetTime });
  };

  //TODO: Is this necessary ? Because we can update only when the user leaves the mouse click
  const handleSlideChange = (number: number) => {
    handleTimeChange(number);
    updateVideoTime({
      roomKey,
      videoTime: number,
    });
  };

  // ========================================//
  // ---------------LISTENER-----------------//
  // ========================================//

  // update local video player based on firebase video collection
  useEffect(() => {
    const roomRef = ref(realtimeDatabase, `room/${roomKey}/video`);
    const unsubscribeListener = onValue(roomRef, (snapshot) => {
      const videoSnapshot = snapshot.val();
      if (videoSnapshot) setVideo(videoSnapshot);
    });
    return unsubscribeListener;
  }, [roomKey]);

  // update local video player based on firebase video collection
  useEffect(() => {
    const roomRef = ref(realtimeDatabase, `room/${roomKey}/users`);
    const unsubscribeListener = onValue(roomRef, (snapshot) => {
      const usersSnapShot = snapshot.val();
      if (usersSnapShot) setUsers(usersSnapShot);
    });
    return unsubscribeListener;
  }, [roomKey]);

  useEffect(() => {
    handleTimeChange(video.currentTime);
  }, [handleTimeChange, video.currentTime]);

  // Every time new user join the party is added to the list
  useEffect(() => {
    if (credential && user) addPartyRoomUser(roomKey, credential, user);
    return () => {
      if (credential) deletePartyRoomUser(roomKey, credential);
    };
  }, [credential, roomKey, user]);

  // to request time every time another person join/leaves the room
  useEffect(() => {
    if (currentTime > 0) {
      updateVideoTime({
        roomKey,
        videoTime: currentTime,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  return {
    video,
    setVideo,
    currentTime,
    setCurrentTime,
    duration,
    setDuration,
    sliding,
    setSliding,
    volume,
    setVolume,
    users,
    setUsers,
    size,
    setSize,
    updateRoomPlaying: updateVideoPlaying,
    updateRoomTime: updateVideoTime,
    handlePause,
    handlePlay,
    handleSlideMouseUp,
    handleSlideMouseDown,
    handleSlideChange,
    handleOnReady,
    handleOnProgress,
    handleClick,
  };
};

export default useVideo;

const VideoContext = createContext(defaultVideoContext);

export const useVideoContext = () => useContext(VideoContext);

export const VideoContextProvider: React.FC<iVideoContextProvider> = ({
  children,
  roomKey,
  playerRef,
}: iVideoContextProvider) => {
  return (
    <VideoContext.Provider value={useVideo(roomKey, playerRef)}>
      {children}
    </VideoContext.Provider>
  );
};

const updateVideoTime = ({ roomKey, videoTime }: iUpdateRoomTime) => {
  update(ref(realtimeDatabase, "room/" + roomKey), {
    "video/currentTime": videoTime,
  });
};

const updateVideoPlaying = ({ roomKey, videoPlaying }: iUpdateRoomPlaying) => {
  update(ref(realtimeDatabase, "room/" + roomKey + "/video"), {
    playing: videoPlaying,
  });
};
