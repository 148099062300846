import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { IconButton, Slider } from "@mui/material";
import { useVideoContext } from "modules/video/useVideo";

import React from "react";

const TimeController: React.FC = () => {
  const {
    video,
    duration,
    currentTime,
    handleSlideChange,
    handlePlay,
    handlePause,
    handleSlideMouseUp,
    handleSlideMouseDown,
    handleClick,
  } = useVideoContext();
  const { playing } = video;

  const handleEvent = (event: any, handlerFunction: any) => {
    const targetTime = parseFloat(event.target.value);
    if (isNaN(targetTime)) return;
    handlerFunction(targetTime);
  };

  return (
    <>
      <IconButton
        onClick={() => (playing ? handlePause() : handlePlay())}
        sx={{ marginRight: 2 }}
      >
        {playing ? <PauseIcon /> : <PlayArrowIcon />}
      </IconButton>
      <Slider
        onMouseUp={(mouseEvent: any) => {
          handleEvent(mouseEvent, handleSlideMouseUp);
        }}
        onMouseDown={(mouseEvent: any) => {
          handleEvent(mouseEvent, handleSlideMouseDown);
        }}
        onClick={(mouseEvent: any) => {
          handleEvent(mouseEvent, handleClick);
        }}
        onChange={(e, value) => handleSlideChange(value as number)}
        min={0}
        max={duration}
        value={currentTime as number}
        style={{}}
      />
    </>
  );
};

export default TimeController;
