import { createGlobalStyle } from "styled-components";
import "./fonts.css";

export default createGlobalStyle`

html,body{
    margin: 0px;
    padding: 0px;
    font-family: 'Rajdhani', sans-serif;
     background-color: #36393F; 
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}


`;
