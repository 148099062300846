import CloseIcon from "@mui/icons-material/Close";
import { Alert, AlertTitle, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useErrorContext } from "modules/error/useError";

const ErrorAlert = () => {
  const { error, cleanError } = useErrorContext();

  if (error)
    return (
      <Box position="absolute" bottom={10} right={10} zIndex={1}>
        <Alert
          action={
            <IconButton
              data-testid="close"
              aria-label="close"
              color="inherit"
              size="small"
              onClick={cleanError}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          variant="filled"
          severity="error"
          sx={{ marginBottom: 2, maxWidth: 400 }}
        >
          <AlertTitle sx={{ fontWeight: "bold" }}> {error.code}</AlertTitle>
          {error.message}
        </Alert>
      </Box>
    );

  return null;
};

export default ErrorAlert;
