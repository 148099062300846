export const VideoFrameConfig = {
  youtube: {
    playerVars: {
      autoplay: 1,
      modestbranding: 1,
      disablekb: 0,
      iv_load_policy: 3,
      rel: 0,
      showinfo: 0,
    },
  },
};
