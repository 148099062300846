import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { Box, IconButton, Slider } from "@mui/material";
import { useVideoContext } from "modules/video/useVideo";

import React, { useState } from "react";

const VolumeControl: React.FC = () => {
  const { setVolume, volume } = useVideoContext();
  const [volumeMemory, setVolumeMemory] = useState(volume);

  return (
    <Box display="flex" width="100%">
      <Box marginRight={2}>
        <IconButton
          onClick={() =>
            volume
              ? (setVolumeMemory(volume), setVolume(0))
              : setVolume(volumeMemory)
          }
        >
          {volume ? <VolumeUpIcon /> : <VolumeOffIcon />}
        </IconButton>
      </Box>
      <Slider
        min={0}
        max={100}
        value={volume * 100}
        onChange={(e, val) => setVolume((val as number) / 100)}
        sx={{ width: "100%" }}
      />
    </Box>
  );
};

export default VolumeControl;
