import { Box } from "@mui/material";
import { useCallContext } from "../../useCall";

const VideoCall = () => {
  const { startCall, remoteVideo, myLocalVideo } = useCallContext();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="absolute"
      top={0}
    >
      {startCall && (
        <>
          <video
            width={200}
            id="webcamVideo"
            ref={myLocalVideo}
            autoPlay
            muted
            playsInline
          />
          <video
            width={200}
            id="remoteVideo"
            ref={remoteVideo}
            autoPlay
            playsInline
          />
        </>
      )}
    </Box>
  );
};
export default VideoCall;
