import { Box } from "@mui/material";
import VideoCall from "modules/call/ui/components/VideoCall/VideoCall";
import Canvas from "modules/canvas/ui/components/Canvas/Canvas";
import { useCanvasContext } from "modules/canvas/useCanvas";
import { usePlaylistContext } from "modules/playlist/usePlaylist";
import { useRoomContext } from "modules/room/useRoom";
import { useVideoContext } from "modules/video/useVideo";
import React, { RefObject } from "react";
import ReactPlayer from "react-player";
import { VideoFrameConfig } from "./VideoFrameConfig";
import { VideoOverlay } from "./styles";

interface Params {
  playerRef: RefObject<ReactPlayer>;
}

const VideoFrame: React.FC<Params> = ({ playerRef }: Params) => {
  const {
    video,
    volume,
    handlePlay,
    handlePause,
    handleOnReady,
    handleOnProgress,
    setDuration,
    size,
  } = useVideoContext();
  const { playing } = video;
  const { data } = useRoomContext();
  const { playlist, handleOnEnded } = usePlaylistContext();
  const { active, overlayRef, canvasRef } = useCanvasContext();
  const handleOnCanPlay = (event: React.MouseEvent) => {
    if (event.target === canvasRef.current || active) {
      return; // Ignore the click event and prevent further execution
    }
    playing ? handlePause() : handlePlay();
  };

  return (
    <VideoOverlay
      ref={overlayRef}
      onClick={(e) => handleOnCanPlay(e)}
      isCanvasActive={active}
    >
      <VideoCall />
      <Box
        display="flex"
        style={{ pointerEvents: "none" }}
        justifyContent="center"
      >
        <ReactPlayer
          width={size.width}
          url={playlist[data.currentVideo].videoUrl}
          ref={playerRef}
          playing={playing}
          config={VideoFrameConfig}
          controls={false}
          volume={volume}
          onPlay={handlePlay}
          onPause={handlePause}
          onReady={handleOnReady}
          autoplay
          onProgress={(timeProgress) => {
            handleOnProgress(timeProgress);
          }}
          progressInterval={100}
          onDuration={(duration) => {
            setDuration(duration);
          }}
          onEnded={handleOnEnded}
          style={{ pointerEvents: "none" }}
        />
      </Box>
      {active && <Canvas />}
    </VideoOverlay>
  );
};

export default VideoFrame;
