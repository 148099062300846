import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import PasswordField from "../PasswordField/PasswordField";

interface Params {
  formik: any;
}

const EmailFormStep: React.FC<Params> = ({ formik }: Params) => {
  return (
    <>
      <TextField
        fullWidth
        name="email"
        type="text"
        label="Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
      />

      <PasswordField
        fullWidth
        name="password"
        label="Password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
      />
      <PasswordField
        fullWidth
        name="passwordConfirmation"
        label="Password Confirmation"
        value={formik.values.passwordConfirmation}
        onChange={formik.handleChange}
        error={
          formik.touched.passwordConfirmation &&
          Boolean(formik.errors.passwordConfirmation)
        }
      />
      <Button variant="contained" type="submit" sx={{ marginTop: 1.5 }}>
        Continue
      </Button>
    </>
  );
};

export default EmailFormStep;
