import { Box } from "@mui/material";
import Banner from "modules/home/ui/components/Banner/Banner";
import ContentList from "modules/playlist/ui/components/ContentList/ContentList";
import { useSearchContext } from "modules/search/useSearch";
import React from "react";

const Home: React.FC = () => {
  const { ytSearchLoading, ytSearchResult } = useSearchContext();
  return (
    <Box>
      <Banner />
      <ContentList loading={ytSearchLoading} searchResult={ytSearchResult} />
    </Box>
  );
};

export default Home;
