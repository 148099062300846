import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { iItem } from "modules/search/useSearch";

interface Params {
  onClick: () => {} | void;
  item: iItem;
}

const VideoCard = ({ onClick, item }: Params) => {
  return (
    <Card sx={{ maxWidth: 300 }} onClick={onClick} key={item.id.videoId}>
      <CardActionArea>
        <Box height={150} sx={{ overflow: "hidden" }}>
          <CardMedia
            component="img"
            image={item.snippet.thumbnails.high.url}
            alt={item.snippet.description}
            sx={{ transform: "scale(1.5)" }}
          />
        </Box>
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            {item.snippet.title.length > 38
              ? item.snippet.title.substring(0, 44) + "..."
              : item.snippet.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {new Date(item.snippet.publishedAt)
              .toLocaleString()
              .replace(",", " -")}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default VideoCard;
