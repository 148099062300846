import GoogleIcon from "@mui/icons-material/Google";
import Button from "@mui/material/Button";
import useAuthContext from "modules/auth/useAuth";
import { useErrorContext } from "modules/error/useError";
import { useNavigate } from "react-router-dom";

//TODO: TEST

const SocialAuth: React.FC = () => {
  const { authenticateWithGoogle } = useAuthContext();
  const { cleanError, error } = useErrorContext();
  const navigate = useNavigate();
  const handleClick = async () => {
    cleanError();
    await authenticateWithGoogle();
    if (error) return;
    const redirectLink = localStorage.getItem("room");
    localStorage.removeItem("room");
    if (redirectLink) {
      navigate(`/room/${redirectLink}`);
    } else {
      navigate("/");
    }
  };

  return (
    <Button
      startIcon={<GoogleIcon />}
      variant="contained"
      sx={{ marginTop: 1.5, fontWeight: "bold" }}
      onClick={handleClick}
      data-testid="social-auth-form"
    >
      Authenticate With Google
    </Button>
  );
};

export default SocialAuth;
